<template>
  <div>
    <a-skeleton active v-if="loading" />

    <div class="row p-0" v-else>
      <div class="col-md-12 p-0 pb-2">
        <!-- add  activityDRAWER -->
        <a-drawer
          :title="$t('inscription.nouvInscri')"
          :width="720"
          :visible="inscriptinActDrawer"
          :body-style="{ paddingBottom: '80px' }"
          @close="onClose"
        >
          <activity-ins-form
            :studentId="studentId"
            @updateActivityInscription="updateActivities"
          ></activity-ins-form>
        </a-drawer>
        <a-button
          type="primary"
          icon="plus"
          class="m-2"
          @click="startInsDrawer"
        >
          {{ $t("inscription.nouvInscri") }}
        </a-button>
      </div>
      <div class="col-md-12 p-0 pb-2">
        <a-collapse
          expand-icon-position="right"
          default-active-key="0"
          @change="changeActivekey"
        >
          <a-collapse-panel
            :header="item.name"
            v-for="(item, index) in data"
            :key="index"
          >
            <a-tag color="cyan" class="mb-2">
              {{
                item.mode == "M" ? "Paiment mensuelle" : "Paiement par tranche"
              }}
            </a-tag>

            <a-table
              :pagination="true"
              :data-source="item.fees"
              :columns="columns"
              rowKey="_id"
            >
              <template slot="isPayed" slot-scope="text">
                <a-tag :color="text ? 'green' : 'red'">
                  <b v-if="text">{{ $t("profil.payes") }}</b>
                  <b v-else> {{ $t("profil.nonPayes") }}</b>
                </a-tag>
              </template>
              <span slot="feeName" slot-scope="text">
                <p v-if="text == 'inscription'">
                  {{ text }}
                </p>
                <p v-else>
                  {{ formatFeeName(text) }}
                </p>
              </span>
              <template slot="operation" slot-scope="text, record">
                <a-button
                  type="primary"
                  :disabled="status != 'active'"
                  @click="ClassRoomModal(record)"
                >
                  <a-icon type="eye" /> {{ $t("profil.paiement") }}
                </a-button>
              </template>
            </a-table>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from "vuex";
import apiClient from "@/services/axios";
import Vue from "vue";
import VueRouter from "vue-router";
import moment from "moment";
Vue.use(VueRouter);
import { formatFeeName } from "@/utilities/settings";
import activityInsForm from "./../activityInsForm";

export default {
  props: {
    studentId: {
      required: true,
    },
  },
  computed: {
    ...mapState(["settings", "user"]),
  },
  components: { activityInsForm },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - paiement scolartié",
    });
  },
  mounted() {
    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );
    if (building) this.reductionType = building.reductionType || "pourcentage";

    /* if (this.reductionType == "dinar")
      this.columns[1].title = "Réduction (TND)";
    else this.columns[1].title = "Réduction (%)";*/

    this.fetchPayments();
  },

  data() {
    return {
      inscriptinActDrawer: false,
      activeCollapseKey: ["0"],

      editingReduction: null,
      feesData: [],
      data: [],
      loading: true,
      loadingSave: false,
      moment,
      reductionType: "dinar",
      columns: [
        {
          title: this.$t("paiement.mois"),
          dataIndex: "feeName",
          key: "feeName",
          scopedSlots: {
            customRender: "feeName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: this.$t("profil.etat"),
          dataIndex: "isPayed",
          key: "isPayed",
          scopedSlots: {
            customRender: "isPayed",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          scopedSlots: { customRender: "isPayed" },

          sorter: (a, b) => a.status.length - b.status.length,
          sortDirections: ["descend", "ascend"],
        },
      ],
    };
  },
  methods: {
    onClose() {
      this.inscriptinActDrawer = false;
    },
    changeActivekey(key) {
      console.log(key);
    },
    formatFeeName,
    startInsDrawer() {
      this.inscriptinActDrawer = true;
    },
    async fetchPayments() {
      this.visibleModal = true;
      this.loading = true;
      await apiClient
        .get("/payment/pvalidator/" + this.studentId)
        .then((res) => {
          this.data = res.data.filter((p) => p.typeAS == "A");
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateActivities(updates) {
      this.inscriptinActDrawer = false;
      this.fetchPayments();
    },
  },
};
</script>
